var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"register"},[_c('div',{staticClass:"container-scroller"},[_c('div',{staticClass:"container-fluid page-body-wrapper full-page-wrapper"},[_c('div',{staticClass:"content-wrapper d-flex align-items-center auth"},[_c('div',{staticClass:"row w-100 flex-grow"},[_c('div',{staticClass:"col-xl-4 col-lg-6 mx-auto"},[_c('div',{staticClass:"auth-form-light text-left p-5"},[_vm._m(0),_c('h4',[_vm._v("New here?")]),_c('h6',{staticClass:"font-weight-light"},[_vm._v("Signing up is easy. It only takes a few steps")]),_c('form',{staticClass:"pt-3"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"mt-3"},[_c('router-link',{staticClass:"btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn",attrs:{"to":"/"}},[_vm._v("SIGN UP")])],1),_c('div',{staticClass:"text-center mt-4 font-weight-light"},[_vm._v(" Already have an account? "),_c('router-link',{staticClass:"text-primary",attrs:{"to":"/auth-pages/login"}},[_vm._v("Login")])],1)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand-logo"},[_c('img',{attrs:{"src":require("../../../assets/images/logo.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control form-control-lg",attrs:{"type":"text","id":"exampleInputUsername1","placeholder":"Username"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control form-control-lg",attrs:{"type":"email","id":"exampleInputEmail1","placeholder":"Email"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('select',{staticClass:"form-control form-control-lg",attrs:{"id":"exampleFormControlSelect2"}},[_c('option',[_vm._v("Country")]),_c('option',[_vm._v("United States of America")]),_c('option',[_vm._v("United Kingdom")]),_c('option',[_vm._v("India")]),_c('option',[_vm._v("Germany")]),_c('option',[_vm._v("Argentina")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control form-control-lg",attrs:{"type":"password","id":"exampleInputPassword1","placeholder":"Password"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"form-check"},[_c('label',{staticClass:"form-check-label text-muted"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox"}}),_vm._v(" I agree to all Terms & Conditions "),_c('i',{staticClass:"input-helper"})])])])
}]

export { render, staticRenderFns }